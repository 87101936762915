<script>
import validators from "../../../utils/validators";

async function findAsync(arr, asyncCallback) {
  const promises = arr.map(asyncCallback);
  const results = await Promise.all(promises);
  const index = results.findIndex((result) => result);
  return arr[index];
}

export default {
  inject: {
    injected: { from: "riseForm", default: undefined },
  },
  inheritAttrs: false,
  props: {
    class: [Array, String, Object],
    label: String,
    mask: [String, Array, Object],
    modelValue: {
      type: [Object, String, Boolean, Number],
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    name: String,
    copyable: {
      type: Boolean,
      default: false,
    },
    type: String,
    loading: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "light",
    },
    preventDebounce: {
      type: Boolean,
      default: false,
    },
    labelSize: {
      type: String,
      default: "base",
    },
  },
  emits: ["update:modelValue", "placeChanged"],
  data() {
    return {
      error: false,
      errorMessage: "There was an error.",
      maskaOptions: {
        mask: this.mask,
      },
      debounce: null,
    };
  },
  computed: {},
  watch: {
    injected: {
      handler() {
        this.checkError();
      },
      deep: true,
    },
  },
  created() {
    if (this.injected?.addValidator) {
      this.injected.addValidator(this.checkError);
    }
  },
  beforeUnmount() {
    if (this.injected?.removeValidator) {
      this.injected.removeValidator(this.checkError);
    }
  },
  methods: {
    copy() {
      if (!this.copyable) {
        return;
      }

      navigator.clipboard.writeText(this.modelValue);
      this.$toastSuccess(
        `${this.label || this.placeholder || "Value"} copied successfully.`
      );
    },
    allRules(required, rules) {
      if (required && !rules.includes("required")) {
        return ["required", ...rules];
      }

      return rules;
    },
    async validate(value, rules) {
      // Line to add the noUnknownCharacters rule to all text fields
      if (Array.isArray(rules)) rules.push("noUnknownCharacters");
      else if (typeof rules === "string")
        rules = ["noUnknownCharacters", rules];
      else if (!rules) rules = ["noUnknownCharacters"];

      if (!Array.isArray(rules) || rules.length <= 0) return true;
      if ((!value || value?.length <= 0) && !rules.includes("required"))
        return true;

      const failedRule = await findAsync(rules, async (rule) => {
        if (
          typeof rule === "function" ||
          (validators[rule] == null && typeof rule !== "object")
        ) {
          return false;
        }

        if (typeof rule === "object") {
          return !(await rule.isValid(value));
        }
        return validators[rule](value).isValid === false;
      });

      if (failedRule) {
        if (typeof failedRule === "object") {
          if (typeof failedRule.errorMessage === "function")
            this.errorMessage = failedRule.errorMessage(value);
          else this.errorMessage = failedRule.errorMessage;
          return !failedRule;
        }
        const failedValidator = validators[failedRule](value);
        if (typeof failedValidator.errorMessage === "function")
          this.errorMessage = failedValidator.errorMessage(value);
        else this.errorMessage = failedValidator.errorMessage;
      }

      return !failedRule;
    },
    async checkError() {
      const validated = await this.validate(
        this.modelValue,
        this.allRules(this.required, this.rules)
      );
      this.error = !validated;
      return validated;
    },
    async onInput($event) {
      await this.$emit("update:modelValue", $event.target.value);
      if (this.preventDebounce) {
        this.error = false;
        return;
      }
      if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(() => {
        this.checkError();
      }, 500);
    },
  },
};
</script>

<template>
  <!-- eslint-disable-next-line vue/no-parsing-error -->
  <div :class="class">
    <div
      v-if="label"
      class="flex items-center"
      :class="{
        'text-white': variant === 'dark',
        'text-gray-800': variant === 'light',
        'mb-2 text-base font-medium sm:mb-4 sm:text-lg sm:font-bold':
          labelSize === 'big',
        'mb-2 text-base font-medium': labelSize === 'base',
      }"
    >
      <label v-if="label" :for="name" class="block min-w-0 flex-1"
        >{{ label }}
      </label>
      <div v-if="$slots['label-append']" class="flex-0">
        <slot name="label-append" />
      </div>
    </div>
    <div class="group relative" @click="copy">
      <input
        v-if="type === 'number'"
        class="block w-full rounded-lg border border-solid px-4 py-[14px] text-base font-normal !outline-none"
        :class="[
          {
            'border-red-500 bg-red-50': error,
          },
          $attrs.disabled
            ? 'border-gray-200 bg-gray-50 text-gray-400'
            : 'border-gray-300 bg-gray-50 text-gray-800 hover:bg-gray-100 focus:border-primary-600 focus:bg-gray-50',
        ]"
        v-bind="$attrs"
        :type="type"
        :value="modelValue"
        @input="onInput"
      />
      <input
        v-else
        v-maska:[maskaOptions]
        class="block w-full rounded-lg border border-solid px-4 py-[14px] text-base font-normal !outline-none"
        :class="[
          {
            'border-red-500 bg-red-50': error,
          },
          $attrs.disabled || $attrs.disabled?.length == 0
            ? 'border-gray-200 bg-gray-50 text-gray-400'
            : 'border-gray-300 bg-gray-50 text-gray-800 hover:bg-gray-100 focus:border-primary-600 focus:bg-gray-50',
        ]"
        v-bind="$attrs"
        :type="type"
        :value="modelValue"
        @input="onInput"
      />
      <div
        v-if="copyable"
        class="absolute right-[6px] top-[6px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-gray-50/80 text-gray-500"
      >
        <font-awesome-icon icon="copy" class="h-[24px] w-[24px]" />
      </div>
      <div
        v-if="copyable"
        class="absolute z-[2] left-0 top-0 hidden h-full w-full cursor-pointer items-center justify-center px-2 text-base font-medium text-gray-800 hover:bg-gray-50/90 group-hover:flex"
      >
        <span class="truncate">
          Click to copy {{ label || placeholder || "" }}
        </span>
        <font-awesome-icon icon="copy" class="ml-2" />
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-3">
        <slot name="append" />
      </div>
      <div
        v-if="loading"
        class="absolute left-0 top-0 flex h-full w-full cursor-wait items-end overflow-hidden rounded-lg"
      >
        <RiseProgressBar />
      </div>
    </div>
    <div v-if="error" class="mt-3 text-sm font-normal text-red-600">
      {{ errorMessage }}
    </div>
  </div>
</template>
